<template>
  <a-drawer
    :title="data.name"
    width="1200"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 功能条 -->
    <div class="tool-view">
      <!-- 日期： -->
      <span class="tool-title">日期：</span>
      <a-range-picker
        v-model:value="dateRange"
        :format="dateFormat"
        :allowClear="false"
        @change="onDateChange"
      />
      <!-- 弹簧 -->
      <div style="flex: 1;"></div>
      <!-- 操作 -->
      <a-button @click="touchDownload">导出数据</a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 金额 -->
        <template v-if="(column.key === 'pay_amount' || column.key === 'first_pay_money' || column.key === 'total_pay_money')">
          {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 首日arpu值 -->
        <template v-if="column.key === 'first_arpu' || column.key === 'total_arpu'">
          {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 百分比 -->
        <template v-if="column.key === 'total_pay_user_count_rate' || column.key === 'total_repeat_pay_user_count_rate'">
          {{ record[column.key] === '-' ? '-' : `${record[column.key]}%` }}
        </template>
      </template>
      <!-- 汇总 -->
      <template #summary>
        <a-table-summary-row>
          <a-table-summary-cell v-for="(item, index) in columns" :key="index">
            <!-- 表格内处理的格式，在汇总数据里面需要再处理一遍 -->
            <span v-if="item.key === 'pay_amount' || item.key === 'new_pay_amount' || item.key === 'first_pay_money' || item.key === 'total_pay_money'">
              {{ summary[item.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((summary[item.key] || 0) / 100, 2, false, true) }}
            </span>
            <!-- 首日arpu值 -->
            <span v-else-if="item.key === 'first_arpu' || item.key === 'total_arpu'">
              {{ summary[item.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((summary[item.key] || 0) / 100, 2, false, true) }}
            </span>
            <!-- 百分比 -->
            <span v-else-if="item.key === 'total_pay_user_count_rate' || item.key === 'total_repeat_pay_user_count_rate'">
              {{ summary[item.key] === '-' ? '-' : `${summary[item.key]}%` }}
            </span>
            <!-- 不需要处理的 -->
            <span v-else>
              {{ summary[item.key] || 0 }}
            </span>
          </a-table-summary-cell>
        </a-table-summary-row>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import * as Ex from "@netjoy/excelex"
import { popularizeDetailData } from '@/api/reseller'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'

// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 数据源
let dataSource = ref([])
// 汇总数据
let summary = ref({})
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '访问用户',
    dataIndex: 'visit_user_count',
    key: 'visit_user_count'
  },
  // {
  //   title: '充值用户',
  //   dataIndex: 'pay_user_count',
  //   key: 'pay_user_count'
  // },
  // {
  //   title: '充值金额',
  //   dataIndex: 'pay_amount',
  //   key: 'pay_amount'
  // },
  // {
  //   title: '新增用户',
  //   dataIndex: 'new_user_count',
  //   key: 'new_user_count'
  // },
  // {
  //   title: '充值新户',
  //   dataIndex: 'new_pay_user_count',
  //   key: 'new_pay_user_count'
  // },
  // {
  //   title: '新户充值率',
  //   dataIndex: 'new_pay_user_rate',
  //   key: 'new_pay_user_rate'
  // },
  {
    title: '首日充值用户',
    dataIndex: 'first_pay_user_count',
    key: 'first_pay_user_count'
  },
  {
    title: '首日充值金额',
    dataIndex: 'first_pay_money',
    key: 'first_pay_money'
  },
  {
    title: '首日arpu值',
    dataIndex: 'first_arpu',
    key: 'first_arpu'
  },
  // {
  //   title: '今日充值金额',
  //   dataIndex: 'pay_amount',
  //   key: 'pay_amount'
  // },
  {
    title: '累计充值金额',
    dataIndex: 'total_pay_money',
    key: 'total_pay_money'
  },
  {
    title: '累计arpu值',
    dataIndex: 'total_arpu',
    key: 'total_arpu'
  },
  {
    title: '充值率',
    dataIndex: 'total_pay_user_count_rate',
    key: 'total_pay_user_count_rate'
  },
  {
    title: '复充率',
    dataIndex: 'total_repeat_pay_user_count_rate',
    key: 'total_repeat_pay_user_count_rate'
  },
  {
    title: '当日充值',
    dataIndex: 'pay_amount',
    key: 'pay_amount'
  },
  // {
  //   title: '新户充值金额',
  //   dataIndex: 'new_pay_amount',
  //   key: 'new_pay_amount'
  // },
  // {
  //   title: '推广费用',
  //   dataIndex: 'real_coin',
  //   key: 'real_coin'
  // },
  // {
  //   title: 'ROI',
  //   dataIndex: 'is_half_past_card',
  //   key: 'is_half_past_card'
  // }
])

// 显示
function showDrawer (record) {
  data.value = record
  // 显示
  visible.value = true
  // 获取数据详情列表
  getPopularizeData()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getPopularizeData()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getPopularizeData()
}

// 获取数据详情列表
function getPopularizeData () {
  isLoading.value = true
  const params = {
    id: data.value.id,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  popularizeDetailData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      // 汇总数据
      summary.value = data.summary
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    id: data.value.id,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: 1,
    page_size: 100000
  }
  // 推广链接名称
  const name = data.value.name
  popularizeDetailData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = [data.summary].concat(data.data)
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'date'
          },
          {
            name: '访问用户',
            field: 'visit_user_count'
          },
          // {
          //   name: '充值用户',
          //   field: 'pay_user_count'
          // },
          // {
          //   name: '充值金额',
          //   field: 'pay_amount'
          // },
          // {
          //   name: '新增用户',
          //   field: 'new_user_count'
          // },
          // {
          //   name: '充值新户',
          //   field: 'new_pay_user_count'
          // },
          // {
          //   name: '新户充值率',
          //   field: 'new_pay_user_rate'
          // },
          // {
          //   name: '新户充值金额',
          //   field: 'new_pay_amount'
          // },
          {
            name: '首日充值用户',
            field: 'first_pay_user_count'
          },
          {
            name: '首日充值金额',
            field: 'first_pay_money'
          },
          {
            name: '首日arpu值',
            field: 'first_arpu'
          },
          // {
          //   name: '今日充值金额',
          //   field: 'pay_amount'
          // },
          {
            name: '累计充值金额',
            field: 'total_pay_money'
          },
          {
            name: '累计arpu值',
            field: 'total_arpu'
          },
          {
            name: '充值率',
            field: 'total_pay_user_count_rate'
          },
          {
            name: '复充率',
            field: 'total_repeat_pay_user_count_rate'
          },
          {
            name: '当日充值',
            field: 'pay_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额
            if (field === 'pay_amount' || field === 'first_pay_money' || field === 'total_pay_money') {
              item.data = item.data === '-' ? '-' : Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              return item
            } else if (field === 'first_arpu' || field === 'total_arpu') {
              item.data = item.data === '-' ? '-' : Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              return item
            } else if (field === 'total_pay_user_count_rate' || field === 'total_repeat_pay_user_count_rate') {
              item.data = item.data === '-' ? '-' : `${json[field]}%`
              return item
            }
          }
          return item
        },  `${name}-推广详情数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>